/* 
 * @Author: 曹俊杰 
 * @Date: 2020-08-24 16:29:39
 * @Module: 注册账号
 */
 <template>
  <div class="login-right-main" style="height:592px;">
    <div class="login-title">账号注册</div>
    <el-form ref="formParm" :model="formParm" style="margin-top:30px" :rules="rules">
      <el-form-item :error="errorMessage?errorMessage.name:''" prop="name">
        <el-input v-model="formParm.name" placeholder="请填写您的真实姓名"></el-input>
      </el-form-item>

      <el-form-item :error="errorMessage?errorMessage.phone:''" prop="phone">
        <el-input v-model="formParm.phone" placeholder="请输入您要注册的手机号"></el-input>
      </el-form-item>

      <el-form-item :error="errorMessage?errorMessage.invitor:''" prop="invitor">
        <el-input v-model="formParm.invitor" placeholder="请输入业务ID">
          <el-tooltip
            class="item"
            effect="dark"
            slot="suffix"
            content="请向对接人员索要业务ID"
            placement="top"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </el-input>
      </el-form-item>

      <el-form-item :error="errorMessage?errorMessage.password:''" prop="password">
        <el-input v-model="formParm.password" placeholder="请输入您的密码" type="password"></el-input>
      </el-form-item>

      <el-form-item
        :error="errorMessage?errorMessage.password_confirmation:''"
        prop="password_confirmation"
      >
        <el-input v-model="formParm.password_confirmation" placeholder="请再次输入你的密码" type="password"></el-input>
      </el-form-item>

      <el-form-item :error="errorMessage?errorMessage.verify_code:''" prop="verify_code">
        <el-input v-model="formParm.verify_code" placeholder="请输入手机验证码" @keyup.enter.native="submitForm">
          <el-button
            type="text"
            slot="suffix"
            style="margin-right:16px;"
            @click="_reqVerifyCode"
          >{{secondrender}}</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-button type="primary" class="btn-style" @click="submitForm">注册</el-button>
    <div style="height:44px;" class="login-default-bottom-btn">
      <router-link to="login">
        <el-button type="text">已有账号？</el-button>
      </router-link>
    </div>
  </div>
</template>
 <script>
export default {
  data() {
    return {
      formParm: {
        name: "",
        password: "",
        password_confirmation: "",
        phone: "",
        verify_code: "",
        invitor: "",
      },
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确的手机号", trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        password_confirmation: [
          {
            required: true,
            message: "请再次输入密码",
            trigger: "blur",
          },
        ],
        name: [{ required: true, message: "请输入真实姓名", trigger: "blur" }],
        invitor: [
          { required: true, message: "请输入业务ID", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确的业务ID", trigger: "blur" },
        ],
        verify_code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
      errorMessage: null,
      second: 0,
    };
  },
  components: {},
  computed: {
    secondrender() {
      return this.second == 0 ? "获取验证码" : this.second + "S";
    },
  },
  mounted() {},
  methods: {
    // 提交表单
    submitForm() {
      this.errorMessage = null;
      this.$refs.formParm.validate((valid) => {
        if (valid) {
          this._reqAuthRegister();
        } else {
          return false;
        }
      });
    },
    //获取验证码
    async _reqVerifyCode() {
      const { phone } = this.formParm;
      if (phone == "") {
        this.errorMessage = {
          phone: "请输入手机号",
        };
        return;
      }
      if (this.second > 0) {
        return;
      }
      const { status_code, message } = await this.$api.registerVerifyeApi({
        phone,
      });
      if (status_code === 200) {
        this.$message({
          message: "获取验证码成功",
          type: "success",
        });
        this.second = 60;
        let js = setInterval(() => {
          this.second--;
          if (this.second == 0) {
            clearInterval(js);
          }
        }, 1000);
      } else if (status_code === 422) {
        this.errorMessage = message;
      } else {
        this.$message({
          message,
          type: "warning",
        });
      }
    },
    // 注册账号请求
    async _reqAuthRegister() {
      const { formParm } = this;
      const { status_code, message } = await this.$api.authRegisterApi({
        ...formParm,
      });
      if (status_code === 200) {
        this.$message({
          message: "注册成功",
          type: "success",
        });
        this.$router.go(-1);
      } else if (status_code === 422) {
        this.errorMessage = message;
      } else {
        this.$message({
          message,
          type: "warning",
        });
      }
    },
  },
};
</script>
 <style scoped lang="less">
@import "./style.less";
</style>